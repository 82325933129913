import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Landing from "./Components/Landing/Landing";
import Thankyou from "./Components/Landing/Thankyou";

function App() {
  return (
    <Router basename={"/"}>
      <div className="App">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/thankyou" element={<Thankyou />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
