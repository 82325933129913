import React from "react";
import Cendrol from "../../Assests/Images/Cendrol.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import PinterestIcon from "@mui/icons-material/Pinterest";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Land_footer = () => {
  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-11 col-md-11">
          <div className="container-fluid">
            <div className="footer">
              <div className="row rowMainFooter">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="footerLogo">
                    <a href="#" title="Cendrol">
                      <img
                        className="lazy"
                        src={Cendrol}
                        alt="Cendrol"
                        title="Cendrol"
                      />
                    </a>
                  </div>
                  <ul className="socialIcons">
                    <li>
                      <a
                        href="https://www.facebook.com/cendrolbuild"
                        target="_blank"
                        title="facebook"
                      >
                        <FacebookIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/Cendrol_in"
                        target="_blank"
                        title="twitter"
                      >
                        <TwitterIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/cendrol"
                        target="_blank"
                        title="linkedin"
                      >
                        <LinkedInIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://instagram.com/cendrolbuild?igshid=s0tg7esmezjo"
                        target="_blank"
                        title="Instagram"
                      >
                        <InstagramIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://pin.it/5cJyHsR"
                        target="_blank"
                        title="Pinterest
"
                      >
                        <PinterestIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCUpqY12z7-GlNroRG09nOSg"
                        target="_blank"
                        title="Youtube"
                      >
                        <YouTubeIcon />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <h4>Contact Info</h4>
                  <ul className="listContactInfo">
                    <li>
                      <EmailOutlinedIcon className="land_li_icons" />
                      <a
                        href="mailto:sales@cendrol.com"
                        title="sales@cendrol.com"
                      >
                        sales@cendrol.com
                      </a>
                    </li>
                    <li>
                      <PhoneEnabledIcon className="land_li_icons" />
                      <a href="tel:9741977477" title="974-197-7477">
                        P: 974-197-7477
                      </a>
                    </li>
                    <li>
                      <LocationOnIcon className="land_li_icons" />
                      <a
                        href="https://www.google.com/maps/place/Cendrol/@12.9061286,77.5912248,45m/data=!3m1!1e3!4m5!3m4!1s0x3bae1519390b0a0d:0x52fdbf1c2b29ee0b!8m2!3d12.9061067!4d77.5910703"
                        target="_blank"
                      >
                        {/* Cendrol Construction Contracts PVT LTD
                      <br />
                      175 &amp; 176, Dollars Colony,
                      <br />
                      Bannerghatta Main Road,
                      <br />
                      JP Nagar, Bangalore 560076 */}
                        Cendrol Construction Contracts PVT LTD
                        <br />
                        299-288, 3rd floor Outer Ring Rd,
                        <br />
                        Jeewan Griha Colony, 2nd Phase,
                        <br />
                        JP Nagar, Bangalore 560076.
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Land_footer;
