import React from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Present1 from "../../Assests/Images/present-img1.png";
import Present2 from "../../Assests/Images/present-img2.png";
import Present3 from "../../Assests/Images/present-img3.png";
import Present4 from "../../Assests/Images/present-img4.png";
import Present5 from "../../Assests/Images/present-img5.png";
import Present6 from "../../Assests/Images/present-img6.png";
import Present7 from "../../Assests/Images/present-img7.png";
import Present8 from "../../Assests/Images/present-img8.png";
import Present9 from "../../Assests/Images/present-img9.png";
import Present10 from "../../Assests/Images/present-img10.png";
import Present11 from "../../Assests/Images/present-img11.png";
import Present12 from "../../Assests/Images/present-img12.png";
import Present13 from "../../Assests/Images/present-img13.png";
import Present14 from "../../Assests/Images/present-img14.png";
import Present15 from "../../Assests/Images/present-img15.png";
import Present16 from "../../Assests/Images/present-img16.png";
import Present17 from "../../Assests/Images/present-img17.png";
import Present18 from "../../Assests/Images/present-img18.png";
import Present19 from "../../Assests/Images/present-img19.png";
import Present20 from "../../Assests/Images/present-img20.png";
import Present21 from "../../Assests/Images/present-img21.png";
import Present22 from "../../Assests/Images/present-img22.png";
import Present23 from "../../Assests/Images/present-img23.png";
import Present24 from "../../Assests/Images/present-img24.png";
import Present25 from "../../Assests/Images/present-img25.jpg";
import Present26 from "../../Assests/Images/present-img26.jpg";
import Present27 from "../../Assests/Images/present-img27.jpg";
import Present28 from "../../Assests/Images/present-img28.jpg";
import Present29 from "../../Assests/Images/present-img29.jpg";
import Present30 from "../../Assests/Images/present-img30.jpg";
import Present31 from "../../Assests/Images/present-img31.jpg";
import Present32 from "../../Assests/Images/present-img32.png";

const Land_Present = () => {
  return (
    <div className="land_present div_overflow">
      <div className="row justify-content-center">
        <div className="col-11 col-md-11">
          <div className="container-fluid">
            <div className="py-4 d-none d-lg-block">
              <div className="d-flex justify-content-between">
                <div>
                  <span className="land_present_in_txt">Present in -</span>
                </div>

                <div>
                  <div
                    className="d-flex carousel-button-group"
                    style={{ gap: "15px" }}
                  >
                    <button
                      className="land_present_arrow_btn"
                      type="button"
                      data-bs-target="#multi-item-example"
                      data-bs-slide="prev"
                    >
                      <BsArrowLeft className="land_arrow_icon" />
                    </button>
                    <button
                      className="land_present_arrow_btn"
                      type="button"
                      data-bs-target="#multi-item-example"
                      data-bs-slide="next"
                    >
                      <BsArrowRight className="land_arrow_icon" />
                    </button>
                  </div>
                </div>
              </div>

              <div
                id="multi-item-example"
                className="carousel slide carousel-multi-item"
                data-bs-ride="carousel"
                data-bs-interval="10000"
              >
                <div className="pt-3 carousel-inner">
                  <div className="carousel-item active">
                    <div className="row align-items-center">
                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present1}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present2}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present3}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present4}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present5}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present6}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present7}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present8}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row align-items-center">
                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present9}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present10}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present11}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present12}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present13}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present14}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present15}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present16}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row align-items-center">
                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present17}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present18}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present19}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present20}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present21}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present22}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present23}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present24}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="row align-items-center">
                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present25}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present26}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present27}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present28}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present29}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present30}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present31}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>

                      <div className="col-md-3 land_cols_present">
                        <div className="land_present_img p-2">
                          <img
                            className="lazy"
                            src={Present32}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-5 d-lg-none d-sm-block">
              <div className="d-flex justify-content-between">
                <div>
                  <span className="land_present_in_txt">Present in -</span>
                </div>

                <div>
                  <div
                    className="d-flex carousel-button-group"
                    style={{ gap: "15px" }}
                  >
                    <button
                      className="land_present_arrow_btn"
                      type="button"
                      data-bs-target="#multi-item-example1"
                      data-bs-slide="prev"
                    >
                      <BsArrowLeft className="land_arrow_icon" />
                    </button>
                    <button
                      className="land_present_arrow_btn"
                      type="button"
                      data-bs-target="#multi-item-example1"
                      data-bs-slide="next"
                    >
                      <BsArrowRight className="land_arrow_icon" />
                    </button>
                  </div>
                </div>
              </div>

              <div
                id="multi-item-example1"
                className="carousel slide carousel-multi-item"
                data-bs-ride="carousel"
                data-bs-interval="5000"
              >
                <div className="pt-3 carousel-inner">
                  <div className="carousel-item active">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present1} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present2} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present3} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present4} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present5} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present6} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present7} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present8} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present9} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present10} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present11} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present12} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present13} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present14} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present15} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present16} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present17} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present18} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present19} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present20} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present21} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present22} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present23} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present24} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present25} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present26} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present27} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present28} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present29} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present30} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present31} alt="" title="" />
                      </div>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <div className="land_cols_present">
                      <div className="land_present_img p-2">
                        <img className="lazy" src={Present32} alt="" title="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Land_Present;
