import React, { useState, useRef, useEffect } from "react";
import "./Land.css";
import LandPresent from "./Land_Present";
import LandCustFeedBack from "./Land_Cust_FeedBack";
import LandFAQ from "./Land_FAQ";
import LandFooter from "./Land_footer";
import Cendrol from "../../Assests/Images/logo.svg";
import DraftsIcon from "@mui/icons-material/Drafts";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import gtag, { install } from "ga-gtag";

// darshan
import p1 from "../../Assests/Images/banner-img.jpg";
import p2 from "../../Assests/Images/banner-img2.jpg";
import p3 from "../../Assests/Images/banner-img3.jpg";
import p4 from "../../Assests/Images/banner-img4.jpg";
//
import people1 from "../../Assests/Images/people-icon1.png";
import people2 from "../../Assests/Images/people-icon2.png";
import people3 from "../../Assests/Images/people-icon3.png";
import people4 from "../../Assests/Images/people-icon4.png";
import people5 from "../../Assests/Images/people-icon5.png";
import people6 from "../../Assests/Images/people-icon6.png";

// Land Projects Start
import project1 from "../../Assests/Images/project-img1.jpg";
import project2 from "../../Assests/Images/project-img2.jpg";
import project3 from "../../Assests/Images/project-img3.jpg";
import project4 from "../../Assests/Images/project-img4.jpg";

import project5 from "../../Assests/Images/project-img5.jpg";
import project6 from "../../Assests/Images/project-img6.jpg";
import project7 from "../../Assests/Images/project-img7.jpg";
import project8 from "../../Assests/Images/project-img8.jpg";

import project9 from "../../Assests/Images/project-img9.jpg";
import project10 from "../../Assests/Images/project-img10.jpg";
import project11 from "../../Assests/Images/project-img11.jpg";
import project12 from "../../Assests/Images/project-img12.jpg";

import project13 from "../../Assests/Images/project-img13.jpg";
import project14 from "../../Assests/Images/project-img14.jpg";
import project15 from "../../Assests/Images/project-img15.jpg";
import project16 from "../../Assests/Images/project-img16.jpg";

import project17 from "../../Assests/Images/project-img17.jpg";
import project18 from "../../Assests/Images/project-img18.jpg";
import project19 from "../../Assests/Images/project-img19.jpg";
import project20 from "../../Assests/Images/project-img20.jpg";

import project21 from "../../Assests/Images/project-img21.jpg";
import project22 from "../../Assests/Images/project-img22.jpg";
import project23 from "../../Assests/Images/project-img23.jpg";
import project24 from "../../Assests/Images/project-img24.jpg";

import project25 from "../../Assests/Images/project-img25.jpg";
import project26 from "../../Assests/Images/project-img26.jpg";
import project27 from "../../Assests/Images/project-img27.jpg";
import project28 from "../../Assests/Images/project-img28.jpg";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// Land Projects end
import PackageSection from "./Land_Package";
import { FaBuilding, FaVihara, FaBath } from "react-icons/fa";
import { IoBedSharp } from "react-icons/io5";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import BalconyIcon from "@mui/icons-material/Balcony";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const Landing = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  // Navagation Form
  const otpfocus = useRef();
  const [number, setnumber] = useState([]);
  const [otp, setotp] = useState([]);
  const [loader, setLoader] = useState(false);

  const [selectedpackage, setselectedpackage] = useState();

  useEffect(() => {
    AutoPopUp();
  }, []);

  const AutoPopUp = () => {
    // Auto Pop Up Starting
    let valid = localStorage.getItem("User");

    if (valid === "True") {
      window.$("#AddUserPopUp").modal("hide");
    } else {
      setTimeout(function () {
        window.$("#AddUserPopUp").modal("show");
      }, 15000);
      window.$("#AddUserPopUp").modal({
        backdrop: "static",
        keyboard: false,
      });
    }
    // Auto Pop Up Ending
  };

  function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    gtag("event", "conversion", {
      send_to: "AW-10857610158/hXzmCNK9xLEDEK73p7ko",
      value: 1.0,
      currency: "INR",
      event_callback: callback,
    });
    return false;
  }

  // function for to chek user has already sent details switching modals
  const handleUserPopUp = () => {
    var user = localStorage.getItem("User");
    if (user === "" || user === null) {
      window.$("#AddUserPopUp").modal("show");
    } else {
      window.$("#thankyounew").modal("show");
      // window.$("#thankyou").modal("show");
    }
  };

  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is Required";
    } else if (!/^[a-z][A-Z\s.]+$/i.test(value)) {
      error = "Enter a Valid Name It should contain only Alphabets";
    } else if (value.length > 30) {
      error = "Name Should not more than 30";
    } else if (value.length < 3) {
      error = "Name Should not less than 3 Characters";
    }
    return error;
  }

  function validateMobileNumber(value) {
    let error;
    if (!value) {
      error = "Mobile Number is Required";
    } else if (value.length > 10) {
      error = "Mobile Number Should not more than 10";
    } else if (value.length < 10) {
      error = "Mobile Number Should not less than 10 ";
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
    ) {
      error = "Invalid Mobile Number...Enter Numeric";
    }
    return error;
  }

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is Required";
    } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validtaeOTP(value) {
    let error;
    if (!value) {
      error = "OTP is Required";
    } else if (value.length > 4) {
      error = "OTP Should not more than 4";
    } else if (value.length < 4) {
      error = "OTP Should Should not less than 4";
    } else if (!/^\d{4}$/i.test(value)) {
      error = "Invalid OTP...Enter Numeric";
    }
    return error;
  }

  // Handle Change for Number & To send Otp
  const handleChangeNumber = (e) => {
    setnumber(e.target.value);
    let mobile = e.target.value;
    // if (mobile.length == 0 && e.which == 48) {
    //   return false;
    // }
    // var regex = /^[0-9]$/;
    // if (mobile.match(regex)) {
    //   if (mobile.length === 10) {
    //     alert("succcesed");
    //   }
    // } else {
    //   alert("err");
    // }
    var regex = /^[0-9]{10}$/;
    if (mobile.length === 10) {
      if (mobile.match(regex)) {
        const data = { mobile: mobile };
        axios.post(`${SERVER_URL}/sendotp`, data).then((res) => {
          let type = res.data.msg.type;
          if (type === "success") {
            document.getElementById("otp_sent").style.display = "block";
            document.getElementById("user_otp").style.display = "block";
            otpfocus.current.focus();
            document.getElementById("resendlink").style.pointerEvents = "auto";
          } else {
            toast.error("Internal Error try after Sometime...", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      } else {
        toast.error("Please Enter a valid Mobile Number", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  // TO Resend OTP
  const resendOTP = () => {
    let sendNumber = number;

    const data = {
      mobile: sendNumber,
    };

    axios.post(`${SERVER_URL}/resendOtp`, data).then((res) => {
      let type = res.data.msg.type;
      if (type === "success") {
        document.getElementById("otp_sent").style.display = "none";
        document.getElementById("otp_re_sent").style.display = "block";
        otpfocus.current.focus();
      } else if (type === "error") {
        alert("OTP limit reached please try again");
      } else {
        alert("Mainteance Error!!!");
      }
    });
  };

  // Handle Change for OTP
  const handleChangeOTP = (e) => {
    setotp(e.target.value);
    let otpverify = e.target.value;
    if (otpverify.length === 4) {
      verifyOtp(otpverify);
    }
  };

  const resetOTP = () => {
    setotp("");
  };

  const verifyOtp = (otpverify) => {
    let sendNumber = number;

    const data = {
      mobile: sendNumber,
      otp: otpverify,
    };

    axios.post(`${SERVER_URL}/verifyOtp`, data).then((res) => {
      let type = res.data.msg.type;
      if (type === "success") {
        document.getElementById("otp_verified").style.display = "block";
        document.getElementById("otp_mismatch").style.display = "none";
        document.getElementById("btn_signup").style.display = "block";
      } else if (type === "error") {
        resetOTP();
        document.getElementById("otp_mismatch").style.display = "block";
        document.getElementById("otp_verified").style.display = "none";
        document.getElementById("btn_signup").style.display = "none";
      }
    });
  };

  const SaveUserData = (data, resetForm) => {
    let Email = data.email;
    Email = Email.toLowerCase();
    const adduser = {
      name: data.fullname,
      email: Email,
      mobile: number,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${SERVER_URL}/salesleads`, adduser, {
        headers,
      })
      .then((res) => {
        let Status = res.data.status;
        if (Status === "success") {
          setLoader(false);
          window.$("#AddUserPopUp").modal("hide");
          resetForm({ data: "" });
          localStorage.setItem("User", "True");
          gtag_report_conversion();
          setnumber("");
          setotp("");
          navigate("/thankyou");
        } else if (Status === "failed") {
          toast.error("Details are Already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          resetForm({ data: "" });
          setnumber("");
          setotp("");
        }
      });
  };

  // Cost Calculator From
  const [counter1, updateCounter1] = useState(0);
  const [counter2, updateCounter2] = useState(0);

  const [counter3, updateCounter3] = useState(0);
  const [counter4, updateCounter4] = useState(0);

  const [counter5, updateCounter5] = useState(0);
  const [counter6, updateCounter6] = useState(0);

  const [slider1, Setslider1] = useState(500);
  const [slider2, Setslider2] = useState(1500);
  const [btnpackage1, Setbtnpackage1] = useState(1850);
  const [btnpackage2, Setbtnpackage2] = useState(2100);
  const [btnpackage3, Setbtnpackage3] = useState(2399);

  const [datecons, Setdate] = useState("");

  const [locationq, Setlocation] = useState("");

  const [totalcost, Settotalcost] = useState(0);

  function handleIncrement1() {
    updateCounter1(counter1 + 1);
  }
  function handleDecrement1() {
    updateCounter1(counter1 - 1 <= 0 ? 0 : counter1 - 1);
  }
  function handleIncrement2() {
    updateCounter2(counter2 + 1);
  }
  function handleDecrement2() {
    updateCounter2(counter2 - 1 <= 0 ? 0 : counter2 - 1);
  }

  function handleIncrement3() {
    updateCounter3(counter3 + 1);
  }
  function handleDecrement3() {
    updateCounter3(counter3 - 1 <= 0 ? 0 : counter3 - 1);
  }

  function handleIncrement4() {
    updateCounter4(counter4 + 1);
  }
  function handleDecrement4() {
    updateCounter4(counter4 - 1 <= 0 ? 0 : counter4 - 1);
  }

  function handleIncrement5() {
    updateCounter5(counter5 + 1);
  }
  function handleDecrement5() {
    updateCounter5(counter5 - 1 <= 0 ? 0 : counter5 - 1);
  }
  function handleIncrement6() {
    updateCounter6(counter6 + 1);
  }
  function handleDecrement6() {
    updateCounter6(counter6 - 1 <= 0 ? 0 : counter6 - 1);
  }

  const removeBackgroundStyle = () => {
    // to remove Background color
    document.getElementById("package1").style.background = "#F4F4F4";
    document.getElementById("package2").style.background = "#F4F4F4";
    // document.getElementById("package3").style.background = "#F4F4F4";

    document.getElementById("pricing").style.backgroundColor = "#f4f4f4";
    // document.getElementById("pricing").style.backgroundColor = "#F4F4F4";

    // to change text color
    document.getElementById("package1_name").style.color = "#A7A7A7";
    document.getElementById("package2_name").style.color = "#A7A7A7";
    // document.getElementById("package3_name").style.color = "#A7A7A7";
  };

  const get_package_value1 = () => {
    setselectedpackage(1850);
    removeBackgroundStyle();
    // update the background color
    document.getElementById("package1").style.background =
      "linear-gradient(108.13deg, #DA9917 -14.49%, #FFFF90 64.66%, #ECBF26 101.91%)";
    Setslider2(1500);
    // to change text color
    document.getElementById("package1_name").style.color = "#000000";
  };

  const get_package_value2 = () => {
    setselectedpackage(2100);
    removeBackgroundStyle();
    // update the background color
    document.getElementById("package2").style.background =
      "linear-gradient(108.13deg, #DA9917 -14.49%, #FFFF90 64.66%, #ECBF26 101.91%)";
    Setslider2(1500);
    // to change text color
    document.getElementById("package2_name").style.color = "#000000";
  };

  // const get_package_value3 = () => {
  //   let package1 = btnpackage3;
  //   document.getElementById("selected_package_value").innerText = package1;
  //   document.getElementById("selected_package_value").value = package1;
  //   removeBackgroundStyle();
  //   // update the background color
  //   document.getElementById("package3").style.background =
  //     "linear-gradient(108.13deg, #DA9917 -14.49%, #FFFF90 64.66%, #ECBF26 101.91%)";
  //   Setslider2(1500);
  //   // to change text color
  //   document.getElementById("package3_name").style.color = "#000000";
  // };

  const unselect_package = () => {
    setselectedpackage("");
    removeBackgroundStyle();
  };

  const cost_calculation = () => {
    // enable_down_button();
    var total_package = selectedpackage;
    var total_cost = 0;
    var pricing = 500;
    var sbua_slider = slider1;
    //total_package = document.getElementById('selected_package_value').innerHTML;
    // total_package = document.getElementById("selected_package_value").value;

    pricing = slider2;
    let location = locationq;
    let selectedDate;
    let selectedFloor = counter1;
    let selectedBedRoom = counter4;
    let selectedBathRoom = counter5;

    try {
      selectedDate = datecons;
    } catch (error) {
      selectedDate = null;
      alert(error);
    }

    if (sbua_slider === 500) {
      alert("Please Select the SBUA Value");
    } else if (!location) {
      alert("Please Enter The Location!");
    } else if (selectedDate === "") {
      alert("Please Select The Date");
    } else if (
      selectedFloor === 0 ||
      selectedBedRoom === 0 ||
      selectedBathRoom === 0
    ) {
      alert("Please Select At Least 1 Floor, Bedroom & Bath Room");
    } else if (total_package === 0 && pricing === 1500) {
      alert("Please Select Either Package or Custom Price");
    } else if (total_package != 0 && pricing != 1500) {
      alert("Please Select Either Package or Custom Price");
    } else {
      if (total_package == 0) {
        total_cost = sbua_slider * slider2;
        Settotalcost(total_cost);
      } else {
        total_cost = sbua_slider * total_package;
        Settotalcost(total_cost);
      }
    }
  };

  function Btn({ children, onClick }) {
    return (
      <button className="btn_increment" onClick={onClick}>
        {children}
      </button>
    );
  }

  // Land Projects
  const [show, toggleShow] = useState(false);

  // thank you
  const removePopup = () => {
    window.$("#thankyounew").modal("hide");
  };

  const navigate = useNavigate();

  return (
    <div>
      {/* <button
        className="mob_cal_btn"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <img src={receipt} width="17%" />
        <img width="17%" alt="" />
        Cost Calculator
      </button> */}
      <section>
        {/* LAND_NAV Start*/}
        <div className="row justify-content-center">
          <div className="col-11 col-md-11">
            <div>
              <nav
                className="navbar navbar-expand-lg fixed-top navbar-light py-3"
                style={{ background: "#ffff" }}
              >
                <div className="container-fluid nav_container">
                  <a className="navbar-brand" href="#">
                    <img
                      src={Cendrol}
                      alt="Cendrol"
                      title="Cendrol"
                      className="lazy cendrol_log"
                    />
                  </a>

                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarTogglerDemo02"
                    aria-controls="navbarTogglerDemo02"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse land_nav_toggle"
                    id="navbarTogglerDemo02"
                  >
                    <div className="navbar-nav ms-auto">
                      <div className="navbar_li_div align-self-center">
                        <ul className="navbar-nav land_nav_ul">
                          <li className="nav-item align-self-center px-3">
                            <a
                              href="mailto:sales@cendrol.com"
                              title="sales@cendrol.com"
                            >
                              <DraftsIcon className="nav_mail_icons" />
                              sales@cendrol.com
                            </a>
                          </li>
                          <li className="nav-item align-self-center px-3">
                            <a
                              href="https://wa.me/9197419774777?text=I%27m%20interested%20in%20Cendrol%20Construction"
                              target="_blank"
                              title="9741977477"
                            >
                              <WhatsAppIcon className="nav_whatapp" />
                              +91 9741977477
                            </a>
                          </li>
                        </ul>
                      </div>

                      <button
                        className="btn-lg btn_get_free_quote"
                        onClick={handleUserPopUp}
                      >
                        Get Free Quote
                      </button>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        {/* LAND_NAV End */}
      </section>

      {/* First Section Start */}
      <section className="sliding_comp">
        {/* <div className="row overlap_section">
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <div className="top_banners_">
              <div className="textBanner">
                <div className="text_overlap">
                  <h3>
                    TRANSFORMING RESIDENTIAL <br />
                    CONSTRUCTION THROUGH <br /> TECHNOLOGY
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div
          id="carouselExampleSlidesOnly1"
          className="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval="5000"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="">
                <div className="">
                  <h5>
                    WE'RE NOT JUST ANOTHER CONSTRUCTION COMPANY. WE'RE CUSTOM
                    HOMES EXPERTS
                  </h5>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="">
                <div className="">
                  <h5>
                    WE'RE A TECH-ENABLED <br /> CONSTRUCTION COMPANY,
                    <br /> BRINGING MASTERPIECES TO LIFE
                  </h5>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="">
                <div className="">
                  <h5>
                    WE'RE TRANSFORMING DREAM HOMES INTO REALITIES, WITH
                    EXQUISITE CRAFTMANSHIP & TOP-RATED BUILDING CONTRACTORS
                  </h5>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="">
                <div className="">
                  <h5>
                    BUILD YOUR HOME TODAY <br /> WITH INDIA's FINEST BUILDING
                    <br />
                    CONTRACTORS
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="carouselExampleSlidesOnly"
          className="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval="5000"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={p1}
                className="d-block w-100"
                alt="Construction Company in bangalore"
                title=""
              />
              <div
                className="carousel-caption d-none d-md-block"
                style={{
                  width: "485px",
                  right: "auto",
                  left: "25px",
                  textAlign: "left",
                  bottom: "20%",
                  color: "#fff",
                  // backgroundColor: "rgba(0, 0, 0, 0.7)",
                  // padding: "22px",
                  fontFamily: "fb",
                }}
              >
                <div className="top_banners_">
                  <div className="textBanner">
                    <div className="text_overlap">
                      <h3>
                        WE'RE NOT JUST ANOTHER CONSTRUCTION COMPANY <br />
                        WE'RE CUSTOM HOMES EXPERTS
                        <br />
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={p2}
                className="d-block w-100"
                alt="Building Contractors in bangalore"
                title=""
              />
              <div
                className="carousel-caption d-none d-md-block"
                style={{
                  width: "492px",
                  right: "auto",
                  left: "25px",
                  textAlign: "left",
                  bottom: "20%",
                  color: "#fff",
                  // backgroundColor: "rgba(0, 0, 0, 0.7)",
                  // padding: "22px",
                  fontFamily: "fb",
                }}
              >
                <div className="top_banners_">
                  <div className="textBanner">
                    <div className="text_overlap">
                      <h3>
                        WE'RE A TECH-ENABLED <br />
                        CONSTRUCTION COMPANY, <br />
                        BRINGING MASTERPIECES TO LIFE
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={p3}
                className="d-block w-100"
                alt="Home Construction company in bangalore"
                title=""
              />
              <div className="carousel-caption carousel_txt d-none d-md-block">
                <div className="top_banners_">
                  <div className="textBanner">
                    <div className="text_overlap">
                      <h3>
                        WE'RE TRANSFORMING DREAM HOMES INTO
                        <br />
                        REALITIES, WITH EXQUISITE CRAFTMANSHIP & <br />
                        TOP-RATED BUILDING CONTRACTORS
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={p4}
                className="d-block w-100"
                alt="Residential Construction company in bangalore"
                title=""
              />
              <div
                className="carousel-caption d-none d-md-block"
                style={{
                  width: "485px",
                  right: "auto",
                  left: "25px",
                  textAlign: "left",
                  bottom: "20%",
                  color: "#fff",
                  // backgroundColor: "rgba(0, 0, 0, 0.7)",
                  // padding: "22px",
                  fontFamily: "fb",
                }}
              >
                <div className="top_banners_">
                  <div className="textBanner">
                    <div className="text_overlap">
                      <h3>
                        BUILD YOUR HOME TODAY <br />
                        WITH INDIA's FINEST BUILDING <br />
                        CONTRACTORS
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="first_sec_form">
          <span className="heading_cost_cal">Cost Calculator</span>
          <div className="py-3">
            <div className="input-wrapper">
              <div className="total_cost">
                <span>Total Cost</span>
              </div>
              <div className="align-self-center">
                {/* <span
                  className="number_field"
                  style={{ fontFamily: "initial" }}
                >
                  &#8377;
                </span> */}
                <span className="rs_symbol number_field">₹</span>
                <span id="cost" name="cost" className="number_field">
                  {totalcost}
                </span>
              </div>
            </div>
          </div>

          <div className="p-1">
            <div className="row">
              <div className="col-6 col-md-6">
                <div>
                  <span className="label_calculator_SBUA">SBUA</span>
                  <div className="pt-2">
                    <input
                      className="range"
                      name="sbua"
                      id="sbua"
                      type="range"
                      step="5"
                      min="500"
                      max="5000"
                      required={true}
                      style={{
                        width: "100%",
                        accentColor: "#221E1F",
                        cursor: "pointer",
                      }}
                      value={slider1}
                      onChange={(e) => Setslider1(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-6">
                <input
                  type="text"
                  name="location"
                  id="location"
                  // onkeypress="return (event.charCode > 64 &&
                  //                   event.charCode < 91) || (event.charCode > 96 && event.charCode <div 123)"
                  placeholder="Plot Location"
                  className="add_location_calculator label_calculator"
                  onChange={(e) => Setlocation(e.target.value)}
                  maxLength="40"
                />
              </div>
            </div>

            <div className="row row_calculator">
              <div className="col-6 col-md-6 range_value_sqrt align-self-center">
                <span id="rangeValue">{slider1}</span> Sq.ft.
              </div>

              <div className="col-6 col-md-6 mt-0">
                {/* <label className="mt-0 mb-0">Construction Date</label> */}
                <input
                  name="calendar"
                  id="from-datepicker"
                  className="add_calendar_calculator label_calculator"
                  type="text"
                  // value={datecons.slice(0, 10).split("-").reverse().join("-")}
                  placeholder="Construction Date"
                  onFocus={(e) => (e.currentTarget.type = "date")}
                  onBlur={(e) => (e.currentTarget.type = "text")}
                  onChange={(e) => Setdate(e.target.value)}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-6 col-md-6">
                <div
                  className="d-flex justify-content-start"
                  style={{ gap: "12px" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="background_all">
                      <FaBuilding className="cal_home_icons" />
                    </div>
                  </div>
                  <div className="ml-3" style={{ lineHeight: "1rem" }}>
                    <span className="spec_name">Floors</span>
                    <div className="">
                      <div style={{ textAlign: "Center" }}>
                        <div className="d-flex align-items-center btn_fill">
                          <Btn onClick={handleDecrement1}>-</Btn>
                          <span
                            style={{
                              fontSize: "15px",
                              padding: "6px",
                              verticalAlign: "Middle",
                            }}
                          >
                            {counter1}
                          </span>
                          <Btn onClick={handleIncrement1}>+</Btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6">
                <div
                  className="d-flex justify-content-start"
                  style={{ gap: "12px" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="background_all">
                      <DirectionsCarFilledIcon className="cal_home_icons" />
                    </div>
                  </div>
                  <div className="ml-3" style={{ lineHeight: "1rem" }}>
                    <span className="spec_name">Car Parking</span>
                    <div className="">
                      <div style={{ textAlign: "Center" }}>
                        <div
                          id="btn_car"
                          className="d-flex align-items-center btn_fill"
                          // style={{ width: "80%" }}
                        >
                          <Btn onClick={handleDecrement2}>-</Btn>
                          <span
                            style={{
                              fontSize: "15px",
                              padding: "6px",
                              verticalAlign: "Middle",
                            }}
                          >
                            {counter2}
                          </span>
                          <Btn onClick={handleIncrement2}>+</Btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-6 col-md-6">
                <div
                  className="d-flex justify-content-start"
                  style={{ gap: "12px" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="background_all">
                      <FaVihara className="cal_home_icons" />
                    </div>
                  </div>
                  <div className="ml-3" style={{ lineHeight: "1rem" }}>
                    <span className="spec_name">Kitchen</span>
                    <div className="">
                      <div style={{ textAlign: "Center" }}>
                        <div className="d-flex align-items-center btn_fill">
                          <Btn onClick={handleDecrement3}>-</Btn>
                          <span
                            style={{
                              fontSize: "15px",
                              padding: "6px",
                              verticalAlign: "Middle",
                            }}
                          >
                            {counter3}
                          </span>
                          <Btn onClick={handleIncrement3}>+</Btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6">
                <div
                  className="d-flex justify-content-start"
                  style={{ gap: "12px" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="background_all">
                      <IoBedSharp className="cal_home_icons" />
                    </div>
                  </div>
                  <div className="ml-3" style={{ lineHeight: "1rem" }}>
                    <span className="spec_name">Bedroom</span>
                    <div className="">
                      <div style={{ textAlign: "Center" }}>
                        <div className="d-flex align-items-center btn_fill">
                          <Btn onClick={handleDecrement4}>-</Btn>
                          <span
                            style={{
                              fontSize: "15px",
                              padding: "6px",
                              verticalAlign: "Middle",
                            }}
                          >
                            {counter4}
                          </span>
                          <Btn onClick={handleIncrement4}>+</Btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6 col-md-6">
                <div
                  className="d-flex justify-content-start"
                  style={{ gap: "12px" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="background_all">
                      <FaBath className="cal_home_icons" />
                    </div>
                  </div>
                  <div className="ml-3" style={{ lineHeight: "1rem" }}>
                    <span className="spec_name">Bathroom</span>
                    <div className="">
                      <div style={{ textAlign: "Center" }}>
                        <div className="d-flex align-items-center btn_fill">
                          <Btn onClick={handleDecrement5}>-</Btn>
                          <span
                            style={{
                              fontSize: "15px",
                              padding: "6px",
                              verticalAlign: "Middle",
                            }}
                          >
                            {counter5}
                          </span>
                          <Btn onClick={handleIncrement5}>+</Btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6">
                <div
                  className="d-flex justify-content-start"
                  style={{ gap: "12px" }}
                >
                  <div className="d-flex align-items-center">
                    <div className="background_all">
                      <BalconyIcon className="cal_home_icons" />
                    </div>
                  </div>
                  <div className="ml-3" style={{ lineHeight: "1rem" }}>
                    <span className="spec_name">Balcony</span>
                    <div className="">
                      <div style={{ textAlign: "Center" }}>
                        <div className="d-flex align-items-center btn_fill">
                          <Btn onClick={handleDecrement6}>-</Btn>
                          <span
                            style={{
                              fontSize: "15px",
                              padding: "6px",
                              verticalAlign: "Middle",
                            }}
                          >
                            {counter6}
                          </span>
                          <Btn onClick={handleIncrement6}>+</Btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3" id="move_down">
              <span className="cost_cal_packages">Packages</span>
            </div>
            <div name="package" id="package">
              <div className="row btn_packages">
                <div className="col-6">
                  <button
                    type="button"
                    name="package1"
                    id="package1"
                    // onclick="get_package_value1()"
                    // value="1850"
                    onClick={get_package_value1}
                    value={btnpackage1}
                    className="text-center cost_cal_packages_select"
                  >
                    <span
                      className="cal_cost_rate"
                      id="package1_value"
                      value={btnpackage1}
                    >
                      {btnpackage1}
                    </span>
                    <span className="span_SBUA">/SBUA</span>
                    <p className="m-0 package_names" id="package1_name">
                      Standard
                    </p>
                  </button>
                </div>

                <div className="col-6">
                  <div className="talk-bubble tri-right round btm-left">
                    <p className="cost_cal_recommended">Recommended</p>
                  </div>
                  <button
                    type="button"
                    name="package2"
                    id="package2"
                    // onclick="get_package_value2()"
                    // value="2100"
                    onClick={get_package_value2}
                    value={btnpackage2}
                    className="text-center cost_cal_packages_select"
                  >
                    <span
                      className="cal_cost_rate"
                      id="package2_value"
                      value={btnpackage2}
                    >
                      {btnpackage2}
                    </span>
                    <span className="span_SBUA">/SBUA</span>
                    <p className="m-0 package_names" id="package2_name">
                      Cendrol Plus
                    </p>
                  </button>
                </div>

                {/* <div className="col-4">
                  <button
                    type="button"
                    name="package3"
                    id="package3"
                    // onclick="get_package_value3()"
                    // value="2399"
                    onClick={get_package_value3}
                    value={btnpackage3}
                    className="text-center cost_cal_packages_select"
                  >
                    <span className="cal_cost_rate" value={btnpackage3}>
                      {btnpackage3}
                    </span>
                    <span className="span_SBUA">/SBUA</span>
                    <p className="m-0 package_names" id="package3_name">
                      Premium
                    </p>
                  </button>
                </div> */}

                <input
                  type="text"
                  style={{ display: "none" }}
                  id="selected_package_value"
                  name="selected_package_value"
                  value={selectedpackage}
                />
              </div>
            </div>
            <div className="strike mt-2">
              <span>Or</span>
            </div>
            <div className="mt-2">
              <div className="d-flex justify-content-between">
                <span className="label_calculator_custom_pricing">
                  Custom Pricing
                </span>
                <div>
                  <span id="rangeValues" className="label_number">
                    &#8377;
                    {slider2}
                  </span>
                  <span className="custom_pricing_SBUA">/SBUA</span>
                </div>
              </div>
              <div>
                <div>
                  <input
                    className="range"
                    id="pricing"
                    type="range"
                    name="pricing"
                    // value="1500"
                    min="1500"
                    max="2700"
                    step="2"
                    // onclick="unselect_package()"
                    // oninput="rangeSlider(this.value)"
                    // onchange="rangeSlider(this.value)"
                    style={{
                      width: "100%",
                      accentColor: "#221E1F",
                      cursor: "pointer",
                    }}
                    value={slider2}
                    //   onInput={rangeSlider(this.value)}
                    onChange={(e) => Setslider2(e.target.value)}
                    onClick={unselect_package}
                  />
                </div>
              </div>
            </div>
            <div>
              <a
                href="#"
                // onclick="cost_calculation()"
                onClick={cost_calculation}
                className="btn btn-lg mt-3 btn_cost_calculator"
              >
                Calculate Cost
              </a>
            </div>
          </div>

          <p id="returnmessage4"></p>
        </div>
      </section>

      {/* First Section End */}
      <section>
        <LandPresent />
      </section>
      {/* Y people choose start */}
      <section className="sectionComman sectionPeople">
        <div className="d-flex justify-content-center">
          <div className="col-12 col-md-12 heading-center">
            <h2>Why People Choose us!</h2>
            <p className="sub-heading">
              Design your dream home with ease, here at Cendrol
            </p>
          </div>
        </div>
        <div className="rowPeoplechoose">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="contentChoose">
                  <div className="iconPeople">
                    <img
                      className="lazy"
                      src={people1}
                      alt="Building Construction company in bangalore"
                      title=""
                    />
                  </div>
                  <h6>
                    Photo- Realistic unlimited <br /> 3D design models
                  </h6>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contentChoose">
                  <div className="iconPeople">
                    <img
                      className="lazy"
                      src={people2}
                      alt="Construction Company in bangalore"
                      title=""
                    />
                  </div>
                  <h6>
                    220+ Third-Party <br /> Quality Checks
                  </h6>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contentChoose">
                  <div className="iconPeople">
                    <img
                      className="lazy"
                      src={people3}
                      alt="Building Contractors in bangalore"
                      title=""
                    />
                  </div>
                  <h6>
                    Easy app-based <br /> project tracking
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="contentChoose">
                  <div className="iconPeople">
                    <img
                      className="lazy"
                      src={people4}
                      alt="Home Construction company in bangalore"
                      title=""
                    />
                  </div>
                  <h6>
                    Regular post-construction <br /> inspection reports
                  </h6>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contentChoose">
                  <div className="iconPeople">
                    <img
                      className="lazy"
                      src={people5}
                      alt="Residential Construction company in bangalore"
                      title=""
                    />
                  </div>
                  <h6>
                    Approvals and <br /> Documentation{" "}
                  </h6>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contentChoose">
                  <div className="iconPeople">
                    <img
                      className="lazy"
                      src={people6}
                      alt="Building Construction company in bangalore"
                      title=""
                    />
                  </div>
                  <h6>
                    On-time task completion <br /> as per schedule
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Y people choose start */}

      {/* Yellow Background start */}
      <div className="">
        <section className="bgYellowDesign">
          <div className="container">
            <div className="designBuilding">
              <div className="row align-items-center">
                <div className="col-md-8">
                  <h4>
                    Designs and building your home has <br /> never been this
                    easy
                  </h4>
                </div>
                <div className="col-md-4 text-center">
                  <a
                    className="disableOnSub btn btnWhite"
                    // data-bs-toggle="modal"
                    // data-bs-target="#AddUserPopUp"
                    // href="#"
                    title="Get free quote"
                    onClick={handleUserPopUp}
                  >
                    GET FREE QUOTE
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Yellow Background end */}

      {/* Projects start */}
      <section className="sectionCommanInner sectionHappyCustomerInner">
        {/* <Projects /> */}
        {/* Land Projects start */}
        <div className="container">
          <div className="heading-center mb-5">
            <h2>OUR RECENT WORKS</h2>
          </div>
          <div className="rowHomeDesign">
            <div className="row">
              <div className="col-md-3">
                <div className="contentHomeDesign">
                  <div className="imgDesign">
                    <a href="#">
                      <img className="lazy" src={project1} alt="" title="" />
                    </a>
                  </div>
                  <div className="designDetail">
                    <h4>ANMAY</h4>
                    <div className="sizeDetail">
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Built Up Area: </h6>
                          <h5>2592 SQ. FT.</h5>
                        </div>
                        <div className="col-md-6">
                          <h6>Cost:</h6>
                          <h5>47.9 Lakhs</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Size:</h6>
                          <h5>4BHK</h5>
                        </div>
                        <div className="col-md-6">
                          <h6>No. of bathrooms</h6>
                          <h5>4 bath</h5>
                        </div>
                        <a
                          className="btn disableOnSub mt-3"
                          onClick={handleUserPopUp}
                        >
                          SEE FLOOR PLAN
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="contentHomeDesign">
                  <div className="imgDesign">
                    <a href="#">
                      <img className="lazy" src={project2} alt="" title="" />
                    </a>
                  </div>
                  <div className="designDetail">
                    <h4>Anshul</h4>
                    <div className="sizeDetail">
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Built Up Area: </h6>
                          <h5>2217 SQ. FT.</h5>
                        </div>
                        <div className="col-md-6">
                          <h6>Cost:</h6>
                          <h5>41.0 Lakhs</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Size:</h6>
                          <h5>3BHK</h5>
                        </div>
                        <div className="col-md-6">
                          <h6>No. of bathrooms</h6>
                          <h5>3 bath</h5>
                        </div>
                        <a
                          className="btn disableOnSub mt-3"
                          onClick={handleUserPopUp}
                        >
                          SEE FLOOR PLAN
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="contentHomeDesign">
                  <div className="imgDesign">
                    <a href="#">
                      <img className="lazy" src={project3} alt="" title="" />
                    </a>
                  </div>
                  <div className="designDetail">
                    <h4>Antony</h4>
                    <div className="sizeDetail">
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Built Up Area: </h6>
                          <h5>3702 SQ. FT.</h5>
                        </div>
                        <div className="col-md-6">
                          <h6>Cost:</h6>
                          <h5>68.4 Lakhs</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Size:</h6>
                          <h5>4BHK</h5>
                        </div>
                        <div className="col-md-6">
                          <h6>No. of bathrooms</h6>
                          <h5>4 bath</h5>
                        </div>
                        <a
                          className="btn disableOnSub mt-3"
                          onClick={handleUserPopUp}
                        >
                          SEE FLOOR PLAN
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="contentHomeDesign">
                  <div className="imgDesign">
                    <a href="#">
                      <img className="lazy" src={project4} alt="" title="" />
                    </a>
                  </div>
                  <div className="designDetail">
                    <h4>Anup Ghosh</h4>
                    <div className="sizeDetail">
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Built Up Area: </h6>
                          <h5>2168 SQ. FT.</h5>
                        </div>
                        <div className="col-md-6">
                          <h6>Cost:</h6>
                          <h5>40.1 Lakhs</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Size:</h6>
                          <h5>4BHK</h5>
                        </div>
                        <div className="col-md-6">
                          <h6>No. of bathrooms</h6>
                          <h5>4 bath</h5>
                        </div>
                        <a
                          className="btn disableOnSub mt-3"
                          onClick={handleUserPopUp}
                        >
                          SEE FLOOR PLAN
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {show && (
              <div className="boxLoadMore">
                <div className="row">
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          <img
                            className="lazy"
                            src={project5}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Pramod</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>2369 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>43.8 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>3BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>3 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          <img
                            className="lazy"
                            src={project6}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Dinesh</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>1906 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>35.2 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>4BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>4 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          <img
                            className="lazy"
                            src={project7}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Muthu</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>5611 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>1 Crore</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>8BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>8 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          <img
                            className="lazy"
                            src={project8}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Rahul</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>1809 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>33.4 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>2BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>2 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          <img
                            className="lazy"
                            src={project9}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Origin</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>1534 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>28.3 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>3BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>3 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          {" "}
                          <img
                            className="lazy"
                            src={project10}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Casa Lirio</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>1702 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>31.4 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>4BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>4 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          {" "}
                          <img
                            className="lazy"
                            src={project11}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Picasso</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>2016 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>37.2 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>5BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>4 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          {" "}
                          <img
                            className="lazy"
                            src={project12}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Aeolus</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>2142 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>39.6 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>3BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>3 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          {" "}
                          <img
                            className="lazy"
                            src={project13}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Angle Heaven</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>4620 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>85.4 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>2BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>2 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          {" "}
                          <img
                            className="lazy"
                            src={project14}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Breeze House</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>4883 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>90.2 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>2BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>2 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          {" "}
                          <img
                            className="lazy"
                            src={project15}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Magnolia 1</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>3436 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>63.5 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>4BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>4 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          {" "}
                          <img
                            className="lazy"
                            src={project16}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Magnolia 2</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>3435 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>63.5 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>4BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>4 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          <img
                            className="lazy"
                            src={project17}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Daffodil 1</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>2028 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>37.5 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>3BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>3 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          {" "}
                          <img
                            className="lazy"
                            src={project18}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Daffodil 2</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>2024 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>37.4 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>3BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>3 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          {" "}
                          <img
                            className="lazy"
                            src={project19}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Wood Crest</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>2397 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>44.3 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>3BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>3 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          {" "}
                          <img
                            className="lazy"
                            src={project20}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>West Wind</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>2971 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>54.9 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>4BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>4 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          <img
                            className="lazy"
                            src={project21}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Casa Romera</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>3451 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>63.8 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>5BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>5 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          {" "}
                          <img
                            className="lazy"
                            src={project22}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Casa Rosa</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>2251 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>41.6 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>3BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>3 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          {" "}
                          <img
                            className="lazy"
                            src={project23}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Sandalwood Grove</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>3098 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>57.3 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>4BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>4 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          {" "}
                          <img
                            className="lazy"
                            src={project24}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Honeysuckle</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>2491 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>46.0 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>4BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>4 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          <img
                            className="lazy"
                            src={project25}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Jasmine house</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>1764 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>32.6 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>3BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>3 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          <img
                            className="lazy"
                            src={project26}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Ruby House</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>2052 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>37.9 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>5BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>4 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          <img
                            className="lazy"
                            src={project27}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Blossom Palms</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>2228 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>41.2 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>3BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>3 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="contentHomeDesign">
                      <div className="imgDesign">
                        <a href="#">
                          <img
                            className="lazy"
                            src={project28}
                            alt=""
                            title=""
                          />
                        </a>
                      </div>
                      <div className="designDetail">
                        <h4>Casa Amapola</h4>
                        <div className="sizeDetail">
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Built Up Area: </h6>
                              <h5>2232 SQ. FT.</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>Cost:</h6>
                              <h5>41.3 Lakhs</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h6>Size:</h6>
                              <h5>4BHK</h5>
                            </div>
                            <div className="col-md-6">
                              <h6>No. of bathrooms</h6>
                              <h5>4 bath</h5>
                            </div>
                            <a
                              className="btn disableOnSub mt-3"
                              onClick={handleUserPopUp}
                            >
                              SEE FLOOR PLAN
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="btnLoadMore" onClick={() => toggleShow(!show)}>
              <a href="javascript:;" className="btn">
                {!show ? (
                  <span className="textMore">
                    Load More
                    <KeyboardArrowDownIcon className="arows_" />
                  </span>
                ) : (
                  <span className="textLess">
                    Load Less <KeyboardArrowUpIcon />
                  </span>
                )}
              </a>
            </div>
            <div
              className="modal fade"
              id="modalForm"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <button
                      type="button"
                      className="btn-close close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <div className="container containerForm p-0">
                      <div className="formDesigner formArchitect">
                        <h3>Let’s build</h3>
                        <form id="mainForm2">
                          <div className="form-group">
                            <input
                              type="text"
                              id="name2"
                              className="form-control"
                              placeholder="Name"
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="email"
                              id="email2"
                              className="form-control"
                              placeholder="Email"
                            />
                          </div>
                          <div className="form-group boxOTP">
                            <input
                              type="text"
                              id="phone2"
                              className="form-control"
                              placeholder="Enter 10 Digit Mobile Number"
                              pattern="[1-9]{1}[0-9]{9}"
                            />
                          </div>
                          <div className="form-group boxVerfiyOTP">
                            <input
                              type="text"
                              id="inputOTP2"
                              className="form-control"
                              placeholder="Enter 4 Digit OTP"
                            />
                          </div>
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              id="message2"
                              placeholder="Tell us more"
                            ></textarea>
                          </div>
                          <div className="form-group mb-0 pt-2 boxformSubmitBtn">
                            <input
                              type="submit"
                              disabled
                              id="submit2"
                              className="btn btn-lg btn-block disableOnSub"
                              value="Get Started"
                              title="Get Started"
                            />
                            {/* <div className="formAlert">
                            <p>
                              Please Verify your mobile number before submitting
                              request.
                            </p>
                          </div> */}
                          </div>
                          <p id="returnmessage2"></p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Land Projects End */}
      </section>
      {/* Projects End */}

      {/* Package Section Start */}
      <section
        className="sectionCommanInner sectionHappyCustomerInner"
        style={{ background: "#FAFAFA" }}
      >
        <PackageSection />
      </section>
      {/* Package Section End */}

      <section className="sectionCommanInner sectionHappyCustomerInner div_overflow">
        <LandCustFeedBack />
      </section>

      <div className="sectionCommanInner sectionHappyCustomerInner div_overflow">
        <LandFAQ />
      </div>

      <section className="customdesign_banner">
        <div className="container">
          <h3>
            Want to start custom designing
            <br />
            your home?
          </h3>
          <p className="mb-0">
            <a
              // href="#"
              className="btn btn-xl disableOnSub"
              // data-bs-toggle="modal"
              // data-bs-target="#AddUserPopUp"
              title="Free design consultation"
              onClick={handleUserPopUp}
            >
              Free design consultation
            </a>
          </p>
        </div>
      </section>

      <div className="div_overflow footer">
        <LandFooter />
        <div className="boxCopyright">
          <div className="container">
            <p className="mb-0">
              © {new Date().getFullYear()} www.cendrol.com All rights reserved
            </p>
          </div>
        </div>
      </div>

      {/* POP Up for user Data start */}
      <div
        id="AddUserPopUp"
        className="modal fade"
        // role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div className="d-flex justify-content-between form_head_txt">
                <div>
                  <div className="modal_head_txt">
                    Get A Free Expert Consultation Now.
                  </div>
                  {/* <div className="modal_sub_txt pt-2">
                    Get complete package details directly to your inbox.
                  </div> */}
                </div>
                {/* <div
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <CloseIcon />
                      </div> */}
              </div>

              <div>
                {/* Add User Pop Up Content start */}
                <Formik
                  initialValues={{
                    fullname: "",
                    email: "",
                    number: "",
                  }}
                  onSubmit={(values, { resetForm }) => {
                    SaveUserData(values, resetForm);
                    setLoader(true);
                  }}
                >
                  {({ errors, touched }) => (
                    <div>
                      <Form>
                        <div className="pt-4">
                          <div className="pb-3">
                            <label
                              htmlFor="fullName"
                              className="user_form_label"
                            >
                              Enter Your Name
                            </label>

                            <Field
                              type="text"
                              name="fullname"
                              className="form-control user_form_fileds"
                              id="fullName"
                              placeholder="Enter Full Name"
                              autoComplete="off"
                              validate={validateName}
                            />
                            {errors.fullname && touched.fullname && (
                              <span className="errors">{errors.fullname}</span>
                            )}
                          </div>

                          <div className="pb-3">
                            <label htmlFor="email" className="user_form_label">
                              Enter Your Email
                            </label>
                            <Field
                              type="email"
                              name="email"
                              className="form-control user_form_fileds"
                              id="email"
                              placeholder="Enter Email"
                              autoComplete="off"
                              validate={validateEmail}
                            />
                            {errors.email && touched.email && (
                              <span className="errors">{errors.email}</span>
                            )}
                          </div>

                          <div className="pb-3">
                            <label htmlFor="number" className="user_form_label">
                              Phone Number
                            </label>
                            <div className="d-flex">
                              <span className="number_edit_91">+91</span>
                              <Field
                                type="tel"
                                className="form-control user_form_fileds"
                                id="number"
                                name="number"
                                placeholder="Enter Mobile Number"
                                autoComplete="off"
                                onKeyUp={(e) => handleChangeNumber(e)}
                                onWheel={(e) => e.target.blur()}
                                maxLength={10}
                                onKeyDown={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                validate={validateMobileNumber}
                              />
                            </div>
                            {errors.number && touched.number && (
                              <span className="errors">{errors.number}</span>
                            )}
                            <span
                              id="otp_sent"
                              className="otp_sent"
                              style={{ display: "none" }}
                            >
                              OTP Sent
                            </span>
                            <span
                              id="otp_re_sent"
                              className="otp_sent"
                              style={{ display: "none" }}
                            >
                              OTP Resend Successfully
                            </span>
                          </div>

                          <div id="user_otp">
                            <div className="pb-3" ref={otpfocus}>
                              <label htmlFor="otp" className="user_form_label">
                                Enter Your OTP
                              </label>
                              <Field
                                type="number"
                                name="otp"
                                className="form-control user_form_fileds"
                                id="otp"
                                placeholder="Enter OTP"
                                autoComplete="off"
                                // onChange={(e) => handleChangeOTP(e)}
                                onWheel={(e) => e.target.blur()}
                                onKeyUp={(e) => handleChangeOTP(e)}
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-", "."].includes(e.key) &&
                                  e.preventDefault()
                                }
                                validate={validtaeOTP}
                                // maxLength="4"
                              />
                              {errors.otp && touched.otp && (
                                <span className="errors">{errors.otp}</span>
                              )}
                              <span
                                id="otp_verified"
                                className="otp_sent"
                                style={{ display: "none" }}
                              >
                                OTP verified
                              </span>
                              <span
                                id="otp_mismatch"
                                className="otp_worong"
                                style={{ display: "none" }}
                              >
                                Worng OTP...ReEnter
                              </span>
                            </div>

                            <div className="pb-3">
                              <span className="form_OTP_not">
                                Didn’t Receive OTP
                                <span style={{ fontFamily: "none" }}>?</span>
                              </span>
                              <span
                                id="resendlink"
                                className="resend_otp"
                                onClick={() => resendOTP(number)}
                              >
                                Resend OTP
                              </span>
                            </div>
                          </div>

                          <div id="btn_signup">
                            <button
                              id="signup"
                              type="submit"
                              disabled={loader}
                              className="btn-lg form_signup_btn d-flex justify-content-center"
                            >
                              {loader ? (
                                <div
                                  class="spinner-border loader"
                                  role="status"
                                ></div>
                              ) : (
                                ""
                              )}

                              <p className="mb-0 px-4">Sign Up</p>
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* POP Up for user Data End */}

      {/* Thank you Popup start*/}
      <div
        className="modal fade"
        id="thankyounew"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "9999" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close cls_btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{}}
            ></button>
            <div className="modal-body p-3">
              <div className="text-center thankyou_heading">
                Thank you for
                <br /> your interest in Cendrol.
              </div>
              <div className="d-flex justify-content-center">
                <hr className="thank_line" />
              </div>
              <p className="text-center thank_subheading">
                One of our Sales Representatives will be in contact with you
                shortly.
              </p>
              <div className="d-flex justify-content-center mb-3 mt-4">
                <button className="keep_browsing" onClick={removePopup}>
                  Keep Browsing
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Thank you Popup End*/}
    </div>
  );
};

export default Landing;
