import React, { useState, useRef, useEffect } from "react";
import Cendrol from "../../Assests/Images/logo.svg";
import DraftsIcon from "@mui/icons-material/Drafts";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const Land_NavBar = () => {
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  // Navagation Form
  const otpfocus = useRef();
  const [number, setnumber] = useState([]);
  const [otp, setotp] = useState([]);
  const navigate = useNavigate();

  const handleUserPopUp = () => {
    var user = localStorage.getItem("User");
    if (user === "" || user === null) {
      window.$("#AddUserPopUp").modal("show");
    } else {
      window.$("#thankyounew").modal("show");
      // window.$("#thankyou").modal("show");
    }
  };

  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is Required";
    } else if (!/^[a-z][A-Z\s.]+$/i.test(value)) {
      error = "Enter a Valid Name It should contain only Alphabets";
    } else if (value.length > 30) {
      error = "Name Should not more than 30";
    } else if (value.length < 3) {
      error = "Name Should not less than 3 Characters";
    }
    return error;
  }

  function validateMobileNumber(value) {
    let error;
    if (!value) {
      error = "Mobile Number is Required";
    } else if (value.length > 10) {
      error = "Mobile Number Should not more than 10";
    } else if (value.length < 10) {
      error = "Mobile Number Should not less than 10 ";
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
    ) {
      error = "Invalid Mobile Number...Enter Numeric";
    }
    return error;
  }

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is Required";
    } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validtaeOTP(value) {
    let error;
    if (!value) {
      error = "OTP is Required";
    } else if (value.length > 4) {
      error = "OTP Should not more than 4";
    } else if (value.length < 4) {
      error = "OTP Should Should not less than 4";
    } else if (!/^\d{4}$/i.test(value)) {
      error = "Invalid OTP...Enter Numeric";
    }
    return error;
  }

  // Handle Change for Number & To send Otp
  const handleChangeNumber = (e) => {
    setnumber(e.target.value);
    let mobile = e.target.value;
    // if (mobile.length == 0 && e.which == 48) {
    //   return false;
    // }
    // var regex = /^[0-9]$/;
    // if (mobile.match(regex)) {
    //   if (mobile.length === 10) {
    //     alert("succcesed");
    //   }
    // } else {
    //   alert("err");
    // }
    var regex = /^[0-9]{10}$/;
    if (mobile.length === 10) {
      if (mobile.match(regex)) {
        const data = { mobile: mobile };
        axios.post(`${SERVER_URL}/sendotp`, data).then((res) => {
          let type = res.data.msg.type;
          if (type === "success") {
            document.getElementById("otp_sent").style.display = "block";
            document.getElementById("user_otp").style.display = "block";
            otpfocus.current.focus();
            document.getElementById("resendlink").style.pointerEvents = "auto";
          } else {
            toast.error("Internal Error try after Sometime...", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      } else {
        toast.error("Please Enter a valid Mobile Number", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  // TO Resend OTP
  const resendOTP = () => {
    let sendNumber = number;

    const data = {
      mobile: sendNumber,
    };

    axios.post(`${SERVER_URL}/resendOtp`, data).then((res) => {
      let type = res.data.msg.type;
      if (type === "success") {
        document.getElementById("otp_sent").style.display = "none";
        document.getElementById("otp_re_sent").style.display = "block";
        otpfocus.current.focus();
      } else if (type === "error") {
        alert("OTP limit reached please try again");
      } else {
        alert("Mainteance Error!!!");
      }
    });
  };

  // Handle Change for OTP
  const handleChangeOTP = (e) => {
    setotp(e.target.value);
    let otpverify = e.target.value;
    if (otpverify.length === 4) {
      verifyOtp(otpverify);
    }
  };

  const resetOTP = () => {
    setotp("");
  };

  const verifyOtp = (otpverify) => {
    let sendNumber = number;

    const data = {
      mobile: sendNumber,
      otp: otpverify,
    };

    axios.post(`${SERVER_URL}/verifyOtp`, data).then((res) => {
      let type = res.data.msg.type;
      if (type === "success") {
        document.getElementById("otp_verified").style.display = "block";
        document.getElementById("otp_mismatch").style.display = "none";
        document.getElementById("btn_signup").style.display = "block";
      } else if (type === "error") {
        resetOTP();
        document.getElementById("otp_mismatch").style.display = "block";
        document.getElementById("otp_verified").style.display = "none";
        document.getElementById("btn_signup").style.display = "none";
      }
    });
  };

  const SaveUserData = (data, resetForm) => {
    let Email = data.email;
    Email = Email.toLowerCase();
    const adduser = {
      name: data.fullname,
      email: Email,
      mobile: number,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${SERVER_URL}/salesleads`, adduser, {
        headers,
      })
      .then((res) => {
        let Status = res.data.status;
        if (Status === "success") {
          window.$("#AddUserPopUp").modal("hide");
          resetForm({ data: "" });
          localStorage.setItem("User", "True");
          setnumber("");
          setotp("");
          navigate("/thankyou");
        } else if (Status === "failed") {
          toast.error("Details are Already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          resetForm({ data: "" });
          setnumber("");
          setotp("");
        }
      });
  };

  // thank you
  const removePopup = () => {
    window.$("#thankyounew").modal("hide");
    navigate("/");
  };

  return (
    <div className="div_overflow">
      {/* LAND_NAV Start*/}
      <div className="row justify-content-center">
        <div className="col-11 col-md-11">
          <div>
            <nav
              className="navbar navbar-expand-lg fixed-top navbar-light py-3"
              style={{ background: "#ffff" }}
            >
              <div className="container-fluid nav_container">
                <a className="navbar-brand" href="#">
                  <img
                    src={Cendrol}
                    alt="Cendrol"
                    title="Cendrol"
                    className="lazy cendrol_log"
                  />
                </a>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarTogglerDemo02"
                  aria-controls="navbarTogglerDemo02"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse land_nav_toggle"
                  id="navbarTogglerDemo02"
                >
                  <div className="navbar-nav ms-auto">
                    <div className="navbar_li_div align-self-center">
                      <ul className="navbar-nav land_nav_ul">
                        <li className="nav-item align-self-center px-3">
                          <a
                            href="mailto:sales@cendrol.com"
                            title="sales@cendrol.com"
                          >
                            <DraftsIcon className="nav_mail_icons" />
                            sales@cendrol.com
                          </a>
                        </li>
                        <li className="nav-item align-self-center px-3">
                          <a
                            href="https://wa.me/919741977477?text=I%27m%20interested%20in%20Cendrol%20Construction"
                            target="_blank"
                            title="9741977477"
                          >
                            <WhatsAppIcon className="nav_whatapp" />
                            +91 9741977477
                          </a>
                        </li>
                      </ul>
                    </div>

                    <button
                      className="btn-lg btn_get_free_quote"
                      onClick={handleUserPopUp}
                    >
                      Get Free Quote
                    </button>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* LAND_NAV End */}

      {/* POP Up for user Data start */}
      <div
        id="AddUserPopUp"
        className="modal fade"
        // role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div className="d-flex justify-content-between form_head_txt">
                <div className="">
                  <span>Verify Your Phone Number</span>
                </div>
                {/* <div
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <CloseIcon />
                      </div> */}
              </div>

              <div>
                {/* Add User Pop Up Content start */}
                <Formik
                  initialValues={{
                    fullname: "",
                    email: "",
                    number: "",
                  }}
                  onSubmit={(values, { resetForm }) => {
                    SaveUserData(values, resetForm);
                  }}
                >
                  {({ errors, touched }) => (
                    <div>
                      <Form>
                        <div className="pt-5">
                          <div className="pb-3">
                            <label
                              htmlFor="fullName"
                              className="user_form_label"
                            >
                              Enter Your Name
                            </label>

                            <Field
                              type="text"
                              name="fullname"
                              className="form-control user_form_fileds"
                              id="fullName"
                              placeholder="Enter Full Name"
                              autoComplete="off"
                              validate={validateName}
                            />
                            {errors.fullname && touched.fullname && (
                              <span className="errors">{errors.fullname}</span>
                            )}
                          </div>

                          <div className="pb-3">
                            <label htmlFor="email" className="user_form_label">
                              Enter Your Email
                            </label>
                            <Field
                              type="email"
                              name="email"
                              className="form-control user_form_fileds"
                              id="email"
                              placeholder="Enter Email"
                              autoComplete="off"
                              validate={validateEmail}
                            />
                            {errors.email && touched.email && (
                              <span className="errors">{errors.email}</span>
                            )}
                          </div>

                          <div className="pb-3">
                            <label htmlFor="number" className="user_form_label">
                              Phone Number
                            </label>
                            <Field
                              type="number"
                              className="form-control user_form_fileds"
                              id="number"
                              name="number"
                              placeholder="Enter Mobile Number"
                              autoComplete="off"
                              onKeyUp={(e) => handleChangeNumber(e)}
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-", "."].includes(e.key) &&
                                e.preventDefault()
                              }
                              validate={validateMobileNumber}
                            />
                            {errors.number && touched.number && (
                              <span className="errors">{errors.number}</span>
                            )}
                            <span
                              id="otp_sent"
                              className="otp_sent"
                              style={{ display: "none" }}
                            >
                              OTP Sent
                            </span>
                            <span
                              id="otp_re_sent"
                              className="otp_sent"
                              style={{ display: "none" }}
                            >
                              OTP Resend Successfully
                            </span>
                          </div>

                          <div id="user_otp">
                            <div className="pb-3" ref={otpfocus}>
                              <label htmlFor="otp" className="user_form_label">
                                Enter Your OTP
                              </label>
                              <Field
                                type="number"
                                name="otp"
                                className="form-control user_form_fileds"
                                id="otp"
                                placeholder="Enter OTP"
                                autoComplete="off"
                                onWheel={(e) => e.target.blur()}
                                onKeyUp={(e) => handleChangeOTP(e)}
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-", "."].includes(e.key) &&
                                  e.preventDefault()
                                }
                                validate={validtaeOTP}
                              />
                              {errors.otp && touched.otp && (
                                <span className="errors">{errors.otp}</span>
                              )}
                              <span
                                id="otp_verified"
                                className="otp_sent"
                                style={{ display: "none" }}
                              >
                                OTP verified
                              </span>
                              <span
                                id="otp_mismatch"
                                className="otp_worong"
                                style={{ display: "none" }}
                              >
                                Worng OTP...ReEnter
                              </span>
                            </div>

                            <div className="pb-3">
                              <span className="form_OTP_not">
                                Didn’t Receive OTP
                                <span style={{ fontFamily: "none" }}>?</span>
                              </span>
                              <span
                                id="resendlink"
                                className="resend_otp"
                                onClick={() => resendOTP(number)}
                              >
                                Resend OTP
                              </span>
                            </div>
                          </div>

                          <div id="btn_signup">
                            <button
                              id="signup"
                              type="submit"
                              className="btn-lg form_signup_btn"
                            >
                              Sign Up
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* POP Up for user Data End */}

      {/* Thank you Popup start*/}
      <div
        className="modal fade"
        id="thankyounew"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "9999" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close cls_btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{}}
            ></button>
            <div className="modal-body p-3">
              <div className="text-center thankyou_heading">
                Thank you for
                <br /> your interest in Cendrol.
              </div>
              <div className="d-flex justify-content-center">
                <hr className="thank_line" />
              </div>
              <p className="text-center thank_subheading">
                One of our Sales Representatives will be in contact with you
                shortly.
              </p>
              <div className="d-flex justify-content-center mb-3 mt-4">
                <button className="keep_browsing" onClick={removePopup}>
                  Keep Browsing
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Thank you Popup End*/}
    </div>
  );
};

export default Land_NavBar;
