import React from "react";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import customer1 from "../../Assests/Images/customer-1.png";
import customer2 from "../../Assests/Images/customer-2.png";
import customer3 from "../../Assests/Images/customer-3.png";
import customer4 from "../../Assests/Images/customer-4.jpeg";
import customer5 from "../../Assests/Images/customer-5.jpeg";
import customer6 from "../../Assests/Images/customer-6.jpeg";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const Land_Cust_FeedBack = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-11 col-md-11">
        <div className="container-fluid">
          <div className="py-4 d-none d-lg-block">
            <div className="text-center">
              <div>
                <p className="land_cust_feed_head_txt">
                  OUR CUSTOMERS
                  <FavoriteRoundedIcon className="cust_love_icon" />
                  US
                </p>
              </div>
              <div className="land_cust_feed_sub_txt">
                <span>And we’re sure you will too</span>
              </div>
            </div>

            <div
              id="carousel-feedback"
              className="carousel slide carousel-feedback"
              data-bs-ride="carousel"
              data-bs-interval="5000"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row">
                    <div className="col-md-4">
                      <div
                        className="card"
                        style={{ padding: "10px", border: "none" }}
                      >
                        <div className="mainHappyCustomerBox">
                          <div className="customerImage">
                            <img
                              className="lazy"
                              src={customer1}
                              alt=""
                              title=""
                            />
                          </div>
                          <p>
                            "Site Engineer is always present on site, working
                            hard to get tasks done. I am grateful to Cendrol for
                            building within the promised time-frame and budget,
                            without any issues or complaints on the way."
                          </p>
                          <div className="d-flex justify-content-center land_cust_feedback">
                            <div className="land_cust_line"></div>
                          </div>

                          <div className="text-center">
                            <h4>Rohan</h4>
                            <h5>Bengaluru</h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div
                        className="card"
                        style={{ padding: "10px", border: "none" }}
                      >
                        <div className="mainHappyCustomerBox">
                          <div className="customerImage">
                            <img
                              className="lazy"
                              src={customer2}
                              alt=""
                              title=""
                            />
                          </div>
                          <p>
                            “Right from helping us chose a site to delivering
                            top-notch construction, Cendrol has been a great
                            partner in the journey of building our home.”
                          </p>

                          <div className="d-flex justify-content-center land_cust_feedback">
                            <div className="land_cust_line"></div>
                          </div>

                          <div className="text-center">
                            <h4>Murali</h4>
                            <h5>New Zealand</h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div
                        className="card"
                        style={{ padding: "10px", border: "none" }}
                      >
                        <div className="mainHappyCustomerBox">
                          <div className="customerImage">
                            <img
                              className="lazy"
                              src={customer3}
                              alt=""
                              title=""
                            />
                          </div>
                          <p>
                            “Even being miles away in USA, Rahul kept us in the
                            loop about everything that involved building our
                            dream home. Cendrol did an amazing job at it.”
                          </p>

                          <div className="d-flex justify-content-center land_cust_feedback">
                            <div className="land_cust_line"></div>
                          </div>

                          <div className="text-center">
                            <h4>Huzefa & family</h4>
                            <h5>USA</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="row">
                    <div className="col-md-4">
                      <div
                        className="card"
                        style={{ padding: "10px", border: "none" }}
                      >
                        <div className="mainHappyCustomerBox">
                          <div className="customerImage">
                            <img
                              className="lazy"
                              src={customer4}
                              alt=""
                              title=""
                            />
                          </div>
                          <p>
                            "Rahul is a great person. He understands the need of
                            his clients very well. If you are looking to build a
                            dream home in reasonable budget go for Cendrol. You
                            will start appreciating your decision right from the
                            beginning."
                          </p>
                          <div className="d-flex justify-content-center land_cust_feedback">
                            <div className="land_cust_line"></div>
                          </div>

                          <div className="text-center">
                            <h4>Nalin Diwan</h4>
                            <h5>Bengaluru</h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div
                        className="card"
                        style={{ padding: "10px", border: "none" }}
                      >
                        <div className="mainHappyCustomerBox">
                          <div className="customerImage">
                            <img
                              className="lazy"
                              src={customer5}
                              alt=""
                              title=""
                            />
                          </div>
                          <p>
                            "My point of views cendrol had 5 pillers
                            Transparency in all details, Young stars as Team,
                            Strong design, Systematic approaches, Construction
                            guarantee Nothing required more then this."
                          </p>

                          <div className="d-flex justify-content-center land_cust_feedback">
                            <div className="land_cust_line"></div>
                          </div>

                          <div className="text-center">
                            <h4>NAGARJUN ARJUN</h4>
                            <h5>Bengaluru</h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div
                        className="card"
                        style={{ padding: "10px", border: "none" }}
                      >
                        <div className="mainHappyCustomerBox">
                          <div className="customerImage">
                            <img
                              className="lazy"
                              src={customer6}
                              alt=""
                              title=""
                            />
                          </div>
                          <p>
                            "Professionalism, Punctuality, Quality,
                            Responsiveness, Value Good quality and value for
                            money.. professional people to deal with."
                          </p>

                          <div className="d-flex justify-content-center land_cust_feedback">
                            <div className="land_cust_line"></div>
                          </div>

                          <div className="text-center">
                            <h4>Anil Maddala</h4>
                            <h5>Bengaluru</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  className="land_feedback_arrow_prev"
                  type="button"
                  data-bs-target="#carousel-feedback"
                  data-bs-slide="prev"
                >
                  <BsArrowLeft className="land_arrow_icon" />
                </button>
                <button
                  className="land_feedback_arrow_next"
                  type="button"
                  data-bs-target="#carousel-feedback"
                  data-bs-slide="next"
                >
                  <BsArrowRight className="land_arrow_icon" />
                </button>
              </div>
            </div>
          </div>

          <div className="py-4 d-lg-none d-sm-block">
            <div className="text-center">
              <div>
                <p className="land_cust_feed_head_txt">
                  OUR CUSTOMERS
                  <FavoriteRoundedIcon className="cust_love_icon" />
                  US
                </p>
              </div>
              <div className="land_cust_feed_sub_txt">
                <span>And we’re sure you will too</span>
              </div>
            </div>

            <div
              id="carousel-feedback1"
              className="carousel slide carousel-feedback"
              data-bs-ride="carousel"
              data-bs-interval="5000"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div
                    className="card"
                    style={{ padding: "10px", border: "none" }}
                  >
                    <div className="mainHappyCustomerBox">
                      <div className="customerImage">
                        <img className="lazy" src={customer1} alt="" title="" />
                      </div>
                      <p>
                        "Site Engineer is always present on site, working hard
                        to get tasks done. I am grateful to Cendrol for building
                        within the promised time-frame and budget, without any
                        issues or complaints on the way."
                      </p>
                      <div className="d-flex justify-content-center land_cust_feedback">
                        <div className="land_cust_line"></div>
                      </div>

                      <div className="text-center">
                        <h4>Rohan</h4>
                        <h5>Bengaluru</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div
                    className="card"
                    style={{ padding: "10px", border: "none" }}
                  >
                    <div className="mainHappyCustomerBox">
                      <div className="customerImage">
                        <img className="lazy" src={customer2} alt="" title="" />
                      </div>
                      <p>
                        “Right from helping us chose a site to delivering
                        top-notch construction, Cendrol has been a great partner
                        in the journey of building our home.”
                      </p>

                      <div className="d-flex justify-content-center land_cust_feedback">
                        <div className="land_cust_line"></div>
                      </div>

                      <div className="text-center">
                        <h4>Murali</h4>
                        <h5>New Zealand</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div
                    className="card"
                    style={{ padding: "10px", border: "none" }}
                  >
                    <div className="mainHappyCustomerBox">
                      <div className="customerImage">
                        <img className="lazy" src={customer3} alt="" title="" />
                      </div>
                      <p>
                        “Even being miles away in USA, Rahul kept us in the loop
                        about everything that involved building our dream home.
                        Cendrol did an amazing job at it.”
                      </p>

                      <div className="d-flex justify-content-center land_cust_feedback">
                        <div className="land_cust_line"></div>
                      </div>

                      <div className="text-center">
                        <h4>Huzefa & family</h4>
                        <h5>USA</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div
                    className="card"
                    style={{ padding: "10px", border: "none" }}
                  >
                    <div className="mainHappyCustomerBox">
                      <div className="customerImage">
                        <img className="lazy" src={customer4} alt="" title="" />
                      </div>
                      <p>
                        "Rahul is a great person. He understands the need of his
                        clients very well. If you are looking to build a dream
                        home in reasonable budget go for Cendrol. You will start
                        appreciating your decision right from the beginning."
                      </p>
                      <div className="d-flex justify-content-center land_cust_feedback">
                        <div className="land_cust_line"></div>
                      </div>

                      <div className="text-center">
                        <h4>Nalin Diwan</h4>
                        <h5>Bengaluru</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div
                    className="card"
                    style={{ padding: "10px", border: "none" }}
                  >
                    <div className="mainHappyCustomerBox">
                      <div className="customerImage">
                        <img className="lazy" src={customer5} alt="" title="" />
                      </div>
                      <p>
                        "My point of views cendrol had 5 pillers Transparency in
                        all details, Young stars as Team, Strong design,
                        Systematic approaches, Construction guarantee Nothing
                        required more then this."
                      </p>

                      <div className="d-flex justify-content-center land_cust_feedback">
                        <div className="land_cust_line"></div>
                      </div>

                      <div className="text-center">
                        <h4>NAGARJUN ARJUN</h4>
                        <h5>Bengaluru</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div
                    className="card"
                    style={{ padding: "10px", border: "none" }}
                  >
                    <div className="mainHappyCustomerBox">
                      <div className="customerImage">
                        <img className="lazy" src={customer6} alt="" title="" />
                      </div>
                      <p>
                        "Professionalism, Punctuality, Quality, Responsiveness,
                        Value Good quality and value for money.. professional
                        people to deal with."
                      </p>

                      <div className="d-flex justify-content-center land_cust_feedback">
                        <div className="land_cust_line"></div>
                      </div>

                      <div className="text-center">
                        <h4>Anil Maddala</h4>
                        <h5>Bengaluru</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  className="land_feedback_arrow_prev"
                  type="button"
                  data-bs-target="#carousel-feedback1"
                  data-bs-slide="prev"
                >
                  <BsArrowLeft className="land_arrow_icon" />
                </button>
                <button
                  className="land_feedback_arrow_next"
                  type="button"
                  data-bs-target="#carousel-feedback1"
                  data-bs-slide="next"
                >
                  <BsArrowRight className="land_arrow_icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Land_Cust_FeedBack;
